import React, { useEffect, useRef, useState } from "react";
import useIsInViewport from "../hooks/useIsInViewport";
import { sleep } from "../utils/sleep";
import useAnimate from "../hooks/useAnimate";
import Animate from "./Animate";

export const Mission = (props) => {
  // const headRef = useRef(null);
  // const headAnimate = useAnimate(headRef);

  // const paraRef = useRef(null);
  // const paraAnimate = useAnimate(paraRef, 500);

  // const whyRef = useRef(null);
  // const whyAnimate = useAnimate(whyRef);

  // const reasonRef1 = useRef(null);
  // const reason1Animate = useAnimate(reasonRef1, 500);
  //  const reasonRef2 = useRef(null);
  // const reason2Animate = useAnimate(reasonRef2, 750);

  return (
    <div id="mission">
      <div className="container container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <Animate animationClass={"from-right"}>
                <h2>
                  {props.data
                    ? props.data?.header || "OUR MISSION"
                    : "loading..."}
                </h2>
              </Animate>

              <Animate animationClass={"from-right"} delay={500}>
                <div>
                  {props.data
                    ? props.data.description.map((d, i) => (
                        <p key={`${d}-${i}`}>{d}</p>
                      ))
                    : "loading"}
                </div>
              </Animate>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/mission.webp"
              className="img-responsive from-left"
              alt="about left"
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
