import React, { useRef } from "react";
import useAnimate from "../hooks/useAnimate";

export default function Animate({
  animationClass,
  delay = 0,
  children,
  ...props
}) {
  const ref = useRef(null);
  const animate = useAnimate(ref, delay);
  return (
    <div ref={ref} className={animate ? animationClass : "none"} {...props}>
      {children}
    </div>
  );
}
