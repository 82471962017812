import React, { useEffect, useState } from "react";
import useIsInViewport from "./useIsInViewport";
import { sleep } from "../utils/sleep";

export default function useAnimate(ref, delay = 0) {
  const [animate, setAnimate] = useState(false);
  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    (async () => {
      if (isInViewport && !animate) {
        await sleep(delay);
        setAnimate(true);
      }
    })();
  }, [isInViewport]);

  return animate;
}
