import React from "react";
import Animate from "./Animate";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container container-fluid">
        <Animate animationClass={"from-up"}>
          <div className="section-title">
            <h2>Our Services</h2>
            <p>
              We empower leftist organizations worldwide. Let's make a
              difference together. Contact us for a transformative partnership
              in social change.
            </p>
          </div>
        </Animate>

        <div className="row text-center">
          {props.data
            ? props.data.map((d, i) => (
                <Animate
                  key={`${d.name}-${i}`}
                  animationClass={"from-down"}
                  delay={500 * (i + 1)}
                >
                  <div className="col-md-6">
                    <img
                      className={d.icon}
                      src={d.icon}
                      alt={d.name}
                      height="48px"
                    />
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                </Animate>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
