import React, { useEffect, useRef, useState } from "react";
import useIsInViewport from "../hooks/useIsInViewport";
import { sleep } from "../utils/sleep";
import useAnimate from "../hooks/useAnimate";
import Animate from "./Animate";

export const About = (props) => {
  // const headRef = useRef(null);
  // const headAnimate = useAnimate(headRef);

  // const paraRef = useRef(null);
  // const paraAnimate = useAnimate(paraRef, 500);

  // const whyRef = useRef(null);
  // const whyAnimate = useAnimate(whyRef);

  // const reasonRef1 = useRef(null);
  // const reason1Animate = useAnimate(reasonRef1, 500);
  //  const reasonRef2 = useRef(null);
  // const reason2Animate = useAnimate(reasonRef2, 750);

  return (
    <div id="about">
      <div className="container container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/about.webp"
              className="img-responsive from-left"
              alt="about left"
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <Animate animationClass={"from-right"}>
                <h2>
                  {props.data ? props.data?.header || "ABOUT US" : "loading..."}
                </h2>
              </Animate>

              <Animate animationClass={"from-right"} delay={500}>
                <div>
                  {props.data
                    ? props.data.description.map((d, i) => (
                        <p key={`${d}-${i}`}>{d}</p>
                      ))
                    : "loading"}
                </div>
              </Animate>

              <Animate animationClass={"from-right"}>
                <h3>Why Choose Us?</h3>
              </Animate>

              <div className="list-style">
                <ul>
                  {props.data
                    ? props.data.paragraphs.map((d, i) => (
                        <Animate
                          key={`${d}-${i}`}
                          animationClass={"from-right"}
                          delay={500 * (i + 1)}
                        >
                          <li>{d}</li>
                        </Animate>
                      ))
                    : "loading"}
                </ul>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
